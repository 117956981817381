import * as React from "react"
import { motion } from "framer-motion"
import { addPropertyControls, ControlType, FrameProps } from "framer"

// Code by Remix Lab / @ui_zsolt

interface NegativeGapProps extends FrameProps {
    gap: number
    direction: "horizontal" | "vertical"
    distribute: "start" | "center" | "end" | "space-between"
    alignHorizontal: "flex-start" | "center" | "flex-end"
    alignVertical: "flex-start" | "center" | "flex-end"
    fillColorEnabled: boolean
    fill: string
    children?: React.ReactNode
    layoutId?: string
    forceRender?: boolean
    layoutIdKey?: string
    willChangeTransform?: boolean
    maxWidth?: string | number
    maxHeight?: string | number
    minHeight?: string | number
    reverseOrder: boolean
    padding: number
    individualPadding: boolean
    T: number
    R: number
    B: number
    L: number
}

export function NegativeGap({
    gap = -12,
    direction,
    distribute = "center",
    alignHorizontal = "center",
    alignVertical = "center",
    fillColorEnabled = false,
    fill = "rgba(255, 255, 255, 0)",
    children,
    layoutId,
    forceRender,
    layoutIdKey,
    willChangeTransform,
    maxWidth,
    maxHeight,
    minHeight,
    minWidth,
    reverseOrder = false,
    padding = 0,
    individualPadding = false,
    T = 0,
    R = 0,
    B = 0,
    L = 0,
    ...rest
}: NegativeGapProps): JSX.Element {
    const flexDirection = direction === "vertical" ? "column" : "row"
    const justifyContent = {
        start: "flex-start",
        center: "center",
        end: "flex-end",
        "space-between": "space-between",
    }[distribute]
    const alignItems =
        direction === "vertical" ? alignVertical : alignHorizontal

    const containerStyle = {
        display: "flex",
        flexDirection,
        justifyContent,
        alignItems,
        height: "100%",
        padding: individualPadding ? undefined : `${padding}px`,
        paddingTop: individualPadding ? `${T}px` : undefined,
        paddingRight: individualPadding ? `${R}px` : undefined,
        paddingBottom: individualPadding ? `${B}px` : undefined,
        paddingLeft: individualPadding ? `${L}px` : undefined,
        backgroundColor: fillColorEnabled ? fill : "transparent",
    }

    const renderChildren = () => {
        const childArray = React.Children.toArray(children)
        if (childArray.length === 0) {
            return (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        width: "100%",
                        padding: "32px",
                        boxSizing: "border-box",
                        backgroundColor: "#9168F61A",
                        border: "1px solid #9168F6",
                    }}
                >
                    <div
                        style={{
                            color: "#9168F6",
                            fontWeight: "bold",
                            fontSize: "16px",
                            fontFamily: "Inter, sans-serif",
                            textAlign: "center",
                        }}
                    >
                        Connect to a Frame ⛓︎
                    </div>
                    <div
                        style={{
                            marginTop: "32px",
                            color: "#9168F6",
                            fontWeight: "normal",
                            fontSize: "14px",
                            fontFamily: "Inter, sans-serif",
                            textAlign: "center",
                        }}
                    >
                        Use the three dots or the
                        <br />
                        Connect Elements option
                    </div>
                </div>
            )
        }
        return (reverseOrder ? childArray.reverse() : childArray).map(
            (child, index) => (
                <motion.div
                    key={index}
                    layoutId={layoutId}
                    style={{
                        margin:
                            direction === "vertical"
                                ? `${index === 0 ? 0 : gap}px 0 0 0`
                                : `0 0 0 ${index === 0 ? 0 : gap}px`,
                    }}
                >
                    {child}
                </motion.div>
            )
        )
    }

    return (
        <div {...rest} style={containerStyle}>
            {renderChildren()}
        </div>
    )
}

NegativeGap.defaultProps = {
    gap: -12,
    direction: "horizontal",
    distribute: "center",
    alignHorizontal: "center",
    alignVertical: "center",
    fillColorEnabled: false,
    fill: "#FFFFFF",
    reverseOrder: false,
    padding: 0,
    individualPadding: false,
    T: 0,
    R: 0,
    B: 0,
    L: 0,
}

addPropertyControls(NegativeGap, {
    direction: {
        type: ControlType.Enum,
        title: "Direction",
        options: ["horizontal", "vertical"],
        optionTitles: ["Horizontal", "Vertical"],
        optionIcons: ["direction-horizontal", "direction-vertical"],
        defaultValue: "horizontal",
        displaySegmentedControl: true,
    },
    distribute: {
        type: ControlType.Enum,
        title: "Distribute",
        options: ["start", "center", "end", "space-between"],
        optionTitles: ["Start", "Center", "End", "Space Between"],
        defaultValue: "center",
    },
    alignHorizontal: {
        type: ControlType.Enum,
        title: "Align",
        options: ["flex-start", "center", "flex-end"],
        optionTitles: ["Top", "Middle", "Bottom"],
        optionIcons: ["align-top", "align-middle", "align-bottom"],
        displaySegmentedControl: true,
        defaultValue: "center",
        hidden: ({ direction }) => direction === "vertical",
    },
    alignVertical: {
        type: ControlType.Enum,
        title: "Align",
        options: ["flex-start", "center", "flex-end"],
        optionTitles: ["Left", "Center", "Right"],
        optionIcons: ["align-left", "align-center", "align-right"],
        displaySegmentedControl: true,
        defaultValue: "center",
        hidden: ({ direction }) => direction === "horizontal",
    },
    gap: {
        type: ControlType.Number,
        title: "Gap",
        defaultValue: -12,
        min: -3000,
        max: 3000,
        step: 1,
        displayStepper: true,
    },
    reverseOrder: {
        type: ControlType.Boolean,
        title: "Reverse Order",
        defaultValue: false,
        enabledTitle: "Yes",
        disabledTitle: "No",
    },
    padding: {
        type: ControlType.FusedNumber,
        title: "Padding",
        toggleKey: "individualPadding",
        toggleTitles: ["Uniform", "Individual"],
        valueKeys: ["T", "R", "B", "L"],
        valueLabels: ["T", "R", "B", "L"],
        defaultValue: 0,
        min: 0,
        max: 3000,
    },
    fillColorEnabled: {
        type: ControlType.Boolean,
        title: "Fill Color Enabled",
        defaultValue: false,
        enabledTitle: "Yes",
        disabledTitle: "No",
    },
    fill: {
        type: ControlType.Color,
        title: "Fill Color",
        defaultValue: "#FFFFFF",
        hidden: ({ fillColorEnabled }) => !fillColorEnabled,
    },
    children: {
        type: ControlType.Array,
        title: "Children",
        propertyControl: { type: ControlType.ComponentInstance },
    },
})
